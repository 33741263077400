<template>
  <v-container>
    <v-card outlined>
      <v-card-title>
        <h3 class="text-h3">
          Novo Acordo - {{ clienteNome }}
        </h3>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col
            cols="12"
          >
            <v-simple-table>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th>Pedido</th>
                    <th>Data</th>
                    <th>Vencimento</th>
                    <th>Valor</th>
                    <th>Adicionar?</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="debito in debitosEmAberto"
                    :key="debito.id"
                  >
                    <td>#{{ debito.id }}</td>
                    <td>{{ debito.dataPedido | date }}</td>
                    <td>{{ debito.dataPedido | add30 }}</td>
                    <td>{{ formater.format(debito.totalLiquido) }}</td>
                    <td>
                      <v-checkbox
                        v-if="debito.cobrancasDevedoresPedidos.length === 0"
                        v-model="debito.selected"
                        color="primary"
                      />
                      <span v-else>Acordo em Andamento</span>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-col>
        </v-row>
        <v-row>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Valor Selecionado"
              :value="valorSelecionadoMasked"
              append-icon="mdi-currency-brl"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="cobrancaDevedor.juros"
              type="number"
              label="Juros"
              append-icon="%"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Subtotal (Valor Selecionado + Juros)"
              :value="subtotalMasked"
              append-icon="mdi-currency-brl"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="cobrancaDevedor.descontos"
              type="number"
              label="Descontos (em reais)"
              append-icon="mdi-currency-brl"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Total (Subtotal - Descontos)"
              :value="totalMasked"
              append-icon="mdi-currency-brl"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="cobrancaDevedor.parcelas"
              type="number"
              label="Nº Parcelas"
              append-icon="mdi-numeric-2-box-outline"
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              label="Valor da Parcela"
              :value="valorParcelaMasked"
              append-icon="mdi-currency-brl"
              readonly
            />
          </v-col>
          <v-col
            cols="12"
            sm="6"
          >
            <v-text-field
              v-model="cobrancaDevedor.dataVencimento"
              type="date"
              label="Vencimento"
            />
          </v-col>
          <v-col cols="12">
            <v-textarea
              v-model="cobrancaDevedor.obs"
              label="Observações"
              append-icon="mdi-comment-text-outline"
            />
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          outlined
          @click="onGoAcordosList"
        >
          <v-icon
            small
            left
          >
            mdi-arrow-left
          </v-icon>
          Voltar
        </v-btn>
        <v-spacer />
        <v-btn
          color="success"
          outlined
          :disabled="pedidosSelecionados.length === 0
            || !cobrancaDevedor.dataVencimento"
          @click.stop="onGerarCobranca"
        >
          Gerar Cobrança
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
  import moment from 'moment'

  export default {
    name: 'NovoAcordo',
    data: () => ({
      clienteId: 0,
      clienteNome: 'Nome Cliente',
      debitosEmAberto: [],
      cobrancaDevedor: {
        juros: 0,
        descontos: 0,
        parcelas: 1,
        dataVencimento: '',
        obs: '',
      },
      formater: new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      }),
    }),
    computed: {
      pedidosSelecionados () {
        return this.debitosEmAberto.filter(i => i.selected)
      },
      valorSelecionado () {
        return this.pedidosSelecionados.reduce((x, i) => x + i.totalLiquido, 0)
      },
      valorSelecionadoMasked () {
        return this.formater.format(this.valorSelecionado)
      },
      subtotalSemDescontos () {
        return this.cobrancaDevedor.juros > 0
          ? this.valorSelecionado + ((this.cobrancaDevedor.juros / 100) * this.valorSelecionado)
          : this.valorSelecionado
      },
      subtotalComDescontos () {
        return this.cobrancaDevedor.descontos > 0
          ? this.subtotalSemDescontos - this.cobrancaDevedor.descontos
          : this.subtotalSemDescontos
      },
      subtotalMasked () {
        return this.formater.format(this.subtotalSemDescontos)
      },
      totalMasked () {
        return this.formater.format(this.subtotalComDescontos)
      },
      valorParcelaMasked () {
        return this.cobrancaDevedor.parcelas > 0
          ? this.formater.format(this.subtotalComDescontos / this.cobrancaDevedor.parcelas)
          : this.formater.format(0)
      },
    },
    mounted () {
      if (this.$route.params.id) {
        this.debitosEmAberto = []
        this.clienteId = this.$route.params.id
        this.loadDataHandler()
      } else {
        this.$router.push('/pages/inadimplentes/')
      }
    },
    methods: {
      async loadDataHandler () {
        try {
          const { data } = await this.$http.get(`dividas/cliente/${this.clienteId}`)

          this.clienteNome = data.nome
          this.debitosEmAberto = data.debitosEmAberto.map(debito => {
            debito.selected = false
            return debito
          })
        } catch (e) {
          // console.error(e)
          this.$toast.error('Um erro aconteceu!')
        }
      },
      onGoAcordosList () {
        this.cobrancaDevedor = {
          juros: 0,
          descontos: 0,
          parcelas: 1,
          dataVencimento: '',
          obs: '',
        }

        this.$router.push(`/pages/inadimplentes/${this.clienteId}/acordos/`)
      },
      validateCobrancaDevedorHandler () {
        if (Number(this.cobrancaDevedor.juros) < 0) {
          this.cobrancaDevedor.juros = 0
          this.$toast.error('A taxa de juros não pode ser menor que 0.')
          return false
        }
        if (Number(this.cobrancaDevedor.descontos) < 0) {
          this.cobrancaDevedor.descontos = 0
          this.$toast.error('Os descontos não podem ser menor que 0.')
          return false
        }
        if (Number(this.cobrancaDevedor.parcelas) < 1) {
          this.cobrancaDevedor.parcelas = 1
          this.$toast.error('As parcelas não podem ser menor que 1.')
          return false
        }
        if (moment(this.cobrancaDevedor.dataVencimento) <= moment()) {
          this.cobrancaDevedor.dataVencimento = null
          this.$toast.error('A data de vencimento precisa ser maior que hoje.')
          return false
        }
        return true
      },
      onGerarCobranca () {
        if (this.validateCobrancaDevedorHandler()) {
          this.cobrancaDevedor.idClienteCobrado = this.clienteId
          this.cobrancaDevedor.valorPedidos = this.valorSelecionado
          this.cobrancaDevedor.valorCobrado = this.subtotalComDescontos
          this.cobrancaDevedor.juros = parseFloat(this.cobrancaDevedor.juros)
          this.cobrancaDevedor.descontos = parseFloat(this.cobrancaDevedor.descontos)
          this.cobrancaDevedor.parcelas = parseInt(this.cobrancaDevedor.parcelas)
          this.cobrancaDevedor.pedidosId = this.pedidosSelecionados.map(i => i.id).join(',')

          this.$http.post('dividas/renegociacao', this.cobrancaDevedor)
            .then(({ data }) => {
              if (data.success === false) {
                this.$toast.error(data.message)
              } else {
                this.$toast.success('Novo acordo criado com sucesso!')
                this.onGoAcordosList()
              }
            })
        }
      },
    },
  }
</script>
